<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.825 9.16602L2.5 12.4993L5.825 15.8327V13.3327H11.6667V11.666H5.825V9.16602ZM17.5 7.49935L14.175 4.16602V6.66602H8.33333V8.33268H14.175V10.8327L17.5 7.49935Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcSwap',
  props: {
    size: {
      type: Number,
      default: 20,
    },
  },
}
</script>

<style scoped lang="scss"></style>
